import React from "react";
import AccordionButton from "src/components/accordion-button.js";
import TableFilesDocuments from "src/components/table-files-documents";
import ReactHtmlParser from "react-html-parser";
import ReactIframeResizer from "react-iframe-resizer-super";
import Gallery from "src/components/gallery/gallery";
import TableFiles from "src/components/table-files";

const fetch = require('isomorphic-fetch')

/**
 * Content for Documents page subpages only.
 */
class SubpageArticleDocuments extends React.Component {

  static uniqueID = 100;
  
  constructor(props) {
    super(props);
    this.createGalleries = this.createGalleries.bind(this);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    isGalleries: this.props.tagNames.includes('dev_only_id_documents_photos_and_videos') || false,
    galleries: []
  };

  componentDidMount() {
    /*
    Load galleries data and set it to state. Used fetch() with the REST API instead of GraphQL with allWordpressWpEnviraGallery because the latter doesn't return all the info we need to render the galleries. (The subpages outside of the Documents page get all the necessary info via gallery shortcodes added to the subpages in WP. The shortcode provides more (and different) info than either allWordpressWpEnviraGallery or the REST API.

    An alternative approach to fetch() here in Documents might be to add the shortcode for each gallery to the Documents > Photos & Videos page in WP, then manipulate it with ReactHtmlParser (similar to subpage-article.js). You'd also have to modify other gallery-related functions below. This approach would require more work by a content administrator (i.e., adding each shortcode in two places) but could put galleries in Documents on par with galleries in subpages elsewhere on the site.
    */

    // IMPORTANT: The following fetch() is imperfect. It is currently limited to the first 100 galleries because WP API doesn't return more than 100 at a time. Will need to change if POLB has more than 100 galleries.
    if (this.state.isGalleries) {
      fetch(`${this.props.apiUrl}/wp-json/wp/v2/envira-gallery?status=publish&per_page=100`)
        .then(response => response.json())
        .then(json => {
          this.setState({
            galleries: this.createGalleries(json)
          }, function () {
            if (this.state.galleries.length > 0) {
              // Update the total that appears in parentheses after "Photos & Videos" in subnav
              this.props.setDocumentsGalleriesTotal(this.state.galleries.length);
            }
          })
        })
    }
  }


  replaceWithComponents(node, index) {    
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpdt-c") > -1)) {

      if (Array.isArray(node.children)) {
        let tableNode = node.children.find(current => {
          return current.name === "table" && current.attribs && current.attribs['data-wpdatatable_id'];
        });

        if (tableNode) {
          let tableID = tableNode.attribs['data-wpdatatable_id'];
          let tableUrl = `${process.env.GATSBY_API_URL}/stellar_custom_table/table` + tableID;

          const iframeResizerOptions = {
            checkOrigin: false,
            minHeight: 200,
            scrolling: false,
            heightCalculationMethod: 'documentElementOffset'
          };

          // for iframe sizer to work, we need a unique ID every time we generate this iframe
          // otherwise, on tab switches, the iframe wont be resized
          SubpageArticleDocuments.uniqueID++;

          return (
            <ReactIframeResizer
              key={'custom-table-' + SubpageArticleDocuments.uniqueID}
              id={'custom-table-' + SubpageArticleDocuments.uniqueID}
              src={tableUrl}
              iframeResizerOptions={iframeResizerOptions}
              iframeResizerUrl={false}
              className="stellar-custom-table documents-stellar-custom-table" />
          );
        }

      }

      return <h3>Could not load this table!</h3>;
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("stellar-collapse__title") > -1)) {
      return <AccordionButton yearTitle={node.children[0].data} />
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1) && (node.attribs.class.indexOf("envira-album-wrap") < 0)) {
      // const galleryInfo = node.children[0].attribs['data-gallery-images'];
      // const downloadOption = node.children[0];

      // return <Gallery key={index} galleryInfo={galleryInfo} previewMode="thumbs" downloadable={downloadOption} />;

      let galleryConfig;

      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].attribs['data-gallery-config']) {
          galleryConfig = node.children[i];
          break;
        }
      }

      return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("fileCategoryId") > -1)) {
      return <TableFiles key={index} tableData={this.props.files.find(obj => obj.filesId === node.children[0].data)} perPage="15"/>;
    }
  }

  /**
   * Create array that contains an object for each gallery on the site
   */
  createGalleries(json) {
    const galleries = json.map(data => {
      const items = data.gallery_data.gallery;

      const gallery = {
        title: data.title.rendered,
        galleryInfo: createGalleryInfo(items)
      }

      return gallery;
    });

    return galleries;


    /**
     * Create galleryInfo object for a particular gallery
     */
    function createGalleryInfo(items) {
      /*
        There are two keys that contain an img URL: link and src.
        Sometimes they are identical. Other times, src has dimensions tacked onto the end.
        If it's a Vimeo video, only src provides an img URL, because link is the URL to the video.

        ex (video):
          src     "https://thehelm.polb.com/wp-content/uploads/789210010_1280x720-1024x576.jpg"
          title   "Vimeo video"
          link    "https://vimeo.com/340781896"

        ex 1 (img):
          src     "https://thehelm.polb.com/wp-content/uploads/2018-green-flag-awards_005-1024x851.jpg"
          title   "SM Line Green Flag Award"
          link    "https://thehelm.polb.com/wp-content/uploads/2018-green-flag-awards_005.jpg"

        The only way to know if it's a video is to look for "vimeo.com" in the link value. A separate video field does not come back from the REST API like it does in the data-* attribute of the HTML that results from the shortcode when placing a gallery on pages.
      */
      const galleryInfo = items.map(item => {
        const isVideo = item.link.indexOf('vimeo.com') > -1 ? true : false;
        return (
          {
            title: item.title,
            full: !isVideo ? item.link : item.src,
            thumbnail: getThumbnailImg(item, isVideo),
            video: isVideo ? getVideoEmbedCode(item.link) : null,
            width: item.width,
            height: item.height
          }
        )
      })

      return galleryInfo;
    }

    /**
     * Construct thumbnail URL and return it
     * @param item      All data for a particular gallery item
     * @param isVideo   Boolean
     */
    function getThumbnailImg(item, isVideo) {
      let img;

      // ex: https://thehelm.polb.com/wp-content/uploads/strategicplan2019cover
      let imgMinusExtension;

      // .jpg, .png, etc.
      let imgFileExtension = item.src.substring(item.src.lastIndexOf('.'));

      if (!isVideo) {
        img = item.link;
        imgMinusExtension = img.substring(0, img.lastIndexOf('.'));
      } else {
        const src = item.src;
        const wxhIndex = src.indexOf(`-${item.width}x${item.height}.`);

        if (wxhIndex > -1) {
          imgMinusExtension = src.substring(0, wxhIndex);
        } else {
          imgMinusExtension = src.substring(0, src.lastIndexOf('.'));
        }
      }

      return `${imgMinusExtension}-150x150${imgFileExtension}`;
    }

    /**
     * Return Vimeo embed code
     * @param linkUrl   Formatted like https://vimeo.com/340781896
     */
    function getVideoEmbedCode(linkUrl) {
      let video = {};
      const vimeoId = linkUrl.substring(linkUrl.indexOf('.com/') + 5);

      video.embed_url = `//player.vimeo.com/video/${vimeoId}?autoplay=0&badge=0&byline=0&portrait=0&title=0&api=1&wmode=transparent&fullscreen=1`;

      return video;
    }
  }

  render() {
    const statusClass = this.props.active ? 'article--show' : 'article--hide';

    return (
      /*
        Differences between this and normal subpage-article:
          > this imports TableFilesDocuments
          > this passes in perPage prop, which react-paginate needs. perPage specifies how many items to show in each paginated view.
      */
      <section id={this.props.slug} className={statusClass}>
        {
          this.props.files ||
            (this.props.content.indexOf('visualizer-front') > -1) ||
            (this.props.content.indexOf('envira-gallery-wrap') > -1) ||
            (this.props.content.indexOf('gform_wrapper') > -1) ||
            (this.props.content.indexOf('wpdt-c') > -1) ||
            (this.props.content.indexOf('stats') > -1) ||
            (this.props.content.indexOf('wpfd-single-file') > -1) ||
            (this.props.content.indexOf('wp-block-polb-executives') > -1) ||
            (this.props.content.indexOf('board-meetings') > -1) ||
            (this.props.content.indexOf('stellar-collapse') > -1)
            ?
            <div className="content content--subpage">
              {ReactHtmlParser(this.props.content, { transform: this.replaceWithComponents })}
            </div> :
            <div className="content content--subpage" dangerouslySetInnerHTML={{ __html: this.props.content }} />
        }

        {/* {this.props.files &&
          <TableFilesDocuments tableData={this.props.files} perPage="10" />
        } */}

        {/* {this.state.isGalleries && this.state.galleries &&
          <TableFilesDocuments tableData={this.state.galleries} perPage="10" isGalleries={true} />
        } */}
      </section>
    );
  }
}

export default SubpageArticleDocuments;
